import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Box, Skeleton, Typography, Alert, Button } from '@mui/material';
import { useFindingSystemActionMutation } from 'FindingDetails/store/api';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { TicketTypes } from 'shared/models/data/risk-table.model';
import { TicketModalConfiguration } from 'shared/models/data/ticket-modal-configuration.model';
import { useTranslation } from 'react-i18next';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import FormattedMessage from 'shared/components/FormattedMessage';
import AddOwnerModal from 'FindingDetails/components/FindingContent/components/AddOwnerModal';
import { AvailableUser } from 'FindingDetails/interfaces/Users';
import { IaCItemDetails } from 'IaCFindings/interfaces/IaCFinding';
import ImpactScoreChip from 'shared/components/ImpactScoreChip';
import {
  useCreatePullRequestMutation,
  useGetIacRelatedItemsQuery,
  useUpdateCodeEventMutation,
  useUpdateIacStatusMutation,
} from 'IaCFindings/store/api';
import { FindingActionMenuListType } from 'shared/fixtures/data/action-menu-list.data';
import { ElementWithLabel } from 'FindingDetails/components/FindingContent/components/FindingContentHeader/FindingContentHeader';
import RiskChip from 'Risk/components/RiskChip';
import ActionButton from 'shared/components/ActionButton';
import { PersonPicker } from 'shared/components/DropdownPicker';
import { StatusPicker } from 'shared/components/DropdownPicker/StatusPicker';
import FindingExceptionCreateModal from 'shared/components/FindingExceptionCreateModal';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import SlaChip from 'shared/components/SlaChip';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import ActionTicketResponseModal from 'Risk/components/ActionTicketResponseModal/ActionTicketResponseModal';
import TicketCreateModal from 'Risk/components/TicketCreateModal';
import {
  useBulkCreateTicketForFindingsMutation,
  useBulkUnlinkTicketForFindingsMutation,
} from 'Risk/store/api';
import { FindingTicketActionTypes } from 'shared/enums/FindingTicketActionTypes';
import { BulkCreateTicketData } from 'shared/models/data/bulk-create-ticket-data.model';
import CopyLinkButton from 'shared/components/CopyLinkButton/CopyLinkButton';
import { PullRequestModal } from '../../../Risk/components/PullRequestModal/PullRequestModal';
import { CodeEventItem } from 'Cloud2Code/interfaces/code-event-item.model';
import { RelationType } from 'Cloud2Code/interfaces/cloud-to-code-relation-type.enum';
import { ItemType } from 'FindingDetails/components/FindingContent/components/AddOwnerModal/AddOwnerModal';
import LabelManagementDropdown from 'shared/components/LabelManagementDropdown';
import { SystemLabelType } from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import { useSearchLabelsMutation } from 'shared/store/api';
import LabelList from 'shared/components/LabelList';
import { useLabelSelection } from 'shared/hooks/useLabelSelection';
import { useLabelData } from 'shared/hooks/useLabelData';
import moment from 'moment';
import { dateTimeFormat } from 'shared/services/date.service';

interface Cloud2CodeContentHeaderProps extends BaseComponentProps {
  codeEventData?: IaCItemDetails;
  setRelatedItemsData: React.Dispatch<React.SetStateAction<CodeEventItem[]>>;
  setIsLoadingRelatedItems: React.Dispatch<React.SetStateAction<boolean>>;
  codeEventDataLoading?: boolean;
}

export const Cloud2CodeContentHeader: FunctionComponent<
  Cloud2CodeContentHeaderProps
> = ({
  codeEventData,
  setRelatedItemsData,
  setIsLoadingRelatedItems,
  codeEventDataLoading,
}) => {
  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const [urlSearchParams] = useQueryParams();
  const permissionActions = usePermissions();
  const id = urlSearchParams.openCodeEventId;

  const { t: translation } = useTranslation();

  const { selectedLabels, selectLabel, resetSelection } = useLabelSelection();

  const {
    activeLabels,
    addLabel,
    allLabels,
    filteredAllLabels,
    filteredRecentLabels,
    loading,
    recentLabels,
    searchTerm,
    setSearchTerm,
    refetch,
  } = useLabelData({
    itemType: SystemLabelType.CODE_EVENT,
    itemId: urlSearchParams.openCodeEventId,
  });

  const [ticketModalConfiguration, setTicketModalConfiguration] =
    useState<TicketModalConfiguration>({
      title: '',
      isOpen: false,
      errorMessage: '',
      findingsIds: [''],
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });

  const [bulkActionResponseModalOpen, setBulkActionResponseModalOpen] =
    useState<boolean>(false);
  const [ticketType, setTicketType] = useState<TicketTypes>();
  const [unlinkResponse, setUnlinkResponse] = useState<any>();
  const [createTicketResponse, setCreateTicketResponse] = useState<any>();
  const [hasRelatedPullRequest, setHasRelatedPullRequest] =
    useState<boolean>(false);
  const [isPRModalOpen, setIsPRModalOpen] = useState<boolean>(false);
  const [hasRelatedTicket, setHasRelatedTicket] = useState<boolean>(false);
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] =
    useState<boolean>(false);

  const [performFindingSystemAction, {}] = useFindingSystemActionMutation();

  const [
    searchActiveLabelItems,
    { data: activeLabelItems, isLoading: activeLabelItemsLoading },
  ] = useSearchLabelsMutation();

  const hasCreateItemRelationsPermission = permissionActions.includes(
    ApplicationPermission.FINDINGS_EXTERNAL_ITEM_WRITE
  );
  const hasC2CWritePermission = permissionActions.includes(
    ApplicationPermission.EVENTS_WRITE
  );

  const [
    bulkCreateTicketForFindings,
    {
      data: ticketCreateData,
      isLoading: isCreatingTicket,
      reset: resetBulkCreateTicketData,
    },
  ] = useBulkCreateTicketForFindingsMutation();

  const ticketCreateSuccess = useMemo<boolean>(() => {
    return ticketCreateData?.length
      ? Boolean(ticketCreateData[0]?.isSuccess)
      : false;
  }, [ticketCreateData]);

  const [
    bulkUnlinkTicketForFindings,
    {
      data: unlinkTicketResponse,
      isLoading: unlinkTicketLoading,
      reset: resetBulkUnlinkTicketData,
      isSuccess: unlinkTicketSuccess,
    },
  ] = useBulkUnlinkTicketForFindingsMutation();

  const {
    data: relatedItems,
    isLoading: isLoadingRelatedItems,
    refetch: refetchRelatedItems,
  } = useGetIacRelatedItemsQuery(id);

  useEffect(() => {
    if (ticketCreateSuccess) {
      setHasRelatedTicket(true);
    }
  }, [ticketCreateSuccess]);

  useEffect(() => {
    if (unlinkTicketSuccess) {
      setHasRelatedTicket(false);
    }
  }, [unlinkTicketSuccess]);

  useEffect(() => {
    if (urlSearchParams.openCodeEventId)
      searchActiveLabelItems({
        itemType: SystemLabelType.CODE_EVENT,
        itemId: urlSearchParams.openCodeEventId,
      });
  }, [urlSearchParams.openCodeEventId]);

  useEffect(() => {
    const hasPullRequest = relatedItems?.some(
      (item) => item.relationType === RelationType.PULL_REQUEST
    );
    const hasTicket = relatedItems?.some(
      (item) => item.relationType === RelationType.TICKET
    );

    setHasRelatedPullRequest(hasPullRequest ?? false);
    setHasRelatedTicket(hasTicket ?? false);
    setRelatedItemsData(relatedItems ?? []);
  }, [relatedItems]);
  const [
    createPullRequest,
    {
      isLoading: isPullRequestCreating,
      isSuccess: pullRequestCreated,
      isError: pullRequestCreationFailed,
      error: pullRequestError,
      data: pullRequestResponse,
    },
  ] = useCreatePullRequestMutation();

  useEffect(() => {
    if (pullRequestCreated || pullRequestCreationFailed) {
      setIsPRModalOpen(true);
    }
  }, [pullRequestCreated, pullRequestCreationFailed]);

  useEffect(() => {
    if (unlinkResponse?.length || ticketCreateData?.length) {
      setBulkActionResponseModalOpen(true);
      setCreateTicketResponse(ticketCreateData);
      setUnlinkResponse(unlinkResponse);
    }
  }, [unlinkResponse, ticketCreateData]);

  const [createExceptionModalOpen, setCreateExceptionModalOpen] =
    useState<boolean>(false);

  const handleCreateExceptionModalClose = () => {
    setCreateExceptionModalOpen(false);
  };

  useEffect(() => {
    setIsLoadingRelatedItems(isLoadingRelatedItems);
  }, [isLoadingRelatedItems]);
  const handleUnlinkAction = (response: any) => {
    setTicketType(TicketTypes.UNLINK_TICKET);
    setUnlinkResponse(response);
  };
  useEffect(() => {
    handleUnlinkAction(unlinkTicketResponse);
  }, [unlinkTicketResponse]);

  if (codeEventDataLoading) {
    return (
      <Box className="skeleton-container">
        <Skeleton variant="rectangular" height={40} width={'100%'} />
        <Skeleton variant="rectangular" height={50} width={'100%'} />
        <Skeleton variant="rectangular" height={30} width={'100%'} />
      </Box>
    );
  }

  if (!codeEventData) {
    return <p>{translation(`common.missingValue`)}</p>;
  }

  const handleCreateTicketsModalOpen = () => {
    setTicketModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.ticket.createTicketFailed'),
      title: translation('findings.ticket.createTicket'),
      findingsIds: [codeEventData.findingId],
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.MULTI_TICKET_ICON} />,
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });
  };

  const handleResponseModalClose = () => {
    setBulkActionResponseModalOpen(false);
    setUnlinkResponse([]);
    resetBulkCreateTicketData();
    resetBulkUnlinkTicketData();
    refetchRelatedItems();
  };

  const createNotification = (
    responseItems: any,
    isOpen: boolean,
    handleClose: () => void
  ) => (
    <ActionTicketResponseModal
      isOpen={isOpen}
      handleClose={handleClose}
      responsePayload={responseItems}
      type={ticketType}
    />
  );

  const renderNotificationForOperation = () => {
    if (unlinkResponse && unlinkResponse.length) {
      return createNotification(
        unlinkResponse,
        bulkActionResponseModalOpen,
        handleResponseModalClose
      );
    }

    if (createTicketResponse && createTicketResponse.length) {
      return createNotification(
        createTicketResponse,
        bulkActionResponseModalOpen,
        handleResponseModalClose
      );
    }

    return <></>;
  };

  const handleBulkTicketCreation = (
    ticketCreatePayload: BulkCreateTicketData
  ) => {
    setTicketType(ticketCreatePayload?.ticketType);

    bulkCreateTicketForFindings({
      projectKey: ticketCreatePayload.projectKey,
      assigneeEmail: ticketCreatePayload.assigneeEmail,
      ticketApplication: ticketCreatePayload.ticketApplication,
      notifyTicketAssignee: false,
      ticketType: TicketTypes.CODE_EVENT_TICKET,
      findings: [],
      findingId: codeEventData.findingId,
      filter: {},
      selectionProps: {
        gridType: 'rootCause',
        indeterminateFindingTypes: [],
        selectedFindingIds: [codeEventData.findingId],
        selectedFindingTypes: [],
        unselectedFindingIds: [],
      },
    });
  };

  const handleAddOwner = () => {
    setIsAddOwnerModalOpen(true);
  };

  const handleCreatePullRequest = () => {
    createPullRequest(codeEventData.id);
  };

  const getActionButtonsConfig = () => {
    return [
      {
        name: translation(`findings.details.systemActions.rerun.shortTitle`),
        icon: SVG_ICON_TYPES.RERUN_ICON,
        onClick: () => {
          performFindingSystemAction({
            findingId: codeEventData.findingId,
            action: 'rerun',
          });
        },
      },
      {
        name: translation(`findings.details.systemActions.remap.shortTitle`),
        icon: SVG_ICON_TYPES.SITEMAP_BLACK_ICON,
        onClick: () => {
          performFindingSystemAction({
            findingId: codeEventData.findingId,
            action: 'remap',
          });
        },
      },
    ];
  };

  const renderCreatePRButton = () => {
    if (!codeEventData?.codeFixSuggestion) {
      return <></>;
    }

    return (
      <ActionButton
        icon={SVG_ICON_TYPES.GITHUB_ICON}
        onClick={handleCreatePullRequest}
        disabled={isPullRequestCreating || hasRelatedPullRequest}
      >
        <FormattedMessage
          id="assets.details.createPr"
          defaultMessage="Create PR"
        />
      </ActionButton>
    );
  };

  const renderLabelList = () => {
    if (activeLabelItemsLoading) return <Skeleton height={50} />;

    if (activeLabelItems?.length) return <LabelList items={activeLabelItems} />;

    return <></>;
  };

  const handleBulkUnlinkAction = () => {
    bulkUnlinkTicketForFindings({
      findings: [],
      projectKey: FindingTicketActionTypes.UNLINK_TICKETS,
      filter: {},
      selectionProps: {
        gridType: 'rootCause',
        indeterminateFindingTypes: [],
        selectedFindingIds: [codeEventData.findingId],
        selectedFindingTypes: [],
        unselectedFindingIds: [],
      },
    });
  };

  return (
    <div className="finding-content-header">
      {isCreatingTicket && (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="info">
            <FormattedMessage
              id="findings.ticket.creatingTicket"
              defaultMessage="Creating Ticket"
            ></FormattedMessage>
            ...{' '}
          </Alert>
        </CommonSnackbarContainer>
      )}
      <div className="finding-content-header-title-row">
        <div className="finding-content-header-title">
          {codeEventData?.findingTitle || translation(`common.missingValue`)}
        </div>
        <CopyLinkButton
          excludeSearchParams={['filter', 'order', 'searchKeyword']}
        />
      </div>
      <div className="finding-content-header-elements">
        <ElementWithLabel label={translation('assets.details.impactScore')}>
          <ImpactScoreChip
            value={+Number(codeEventData?.impactScore).toFixed(2)}
          />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label={translation('assets.details.totalFindings')}>
          <Typography className="label-4 " variant="inherit">
            {codeEventData.findingsCount}
          </Typography>
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label={translation('assets.details.maxScore')}>
          <RiskChip riskScore={codeEventData.maxRiskScore} />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="SLA">
          <SlaChip
            slaDate={codeEventData.secondsToSla.toString()}
            isUtc={false}
          />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Assignee">
          <PersonPicker
            readonly={!hasC2CWritePermission}
            selectedPerson={
              {
                id: codeEventData.assigneeUserId || 'Unassigned',
                name: codeEventData.assigneeUserName || 'Unassigned',
                profilePictureUrl: codeEventData?.assigneeProfilePicture,
              } as AvailableUser
            }
            itemId={codeEventData.id}
            property={'codeEventId'}
            updateItemMutation={useUpdateCodeEventMutation}
          />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Status">
          <StatusPicker
            readonly={!hasC2CWritePermission}
            selectedStatusValue={codeEventData.status}
            itemId={codeEventData.id}
            property={'codeEventId'}
            updateItemMutation={useUpdateIacStatusMutation}
          />
        </ElementWithLabel>
      </div>
      {renderLabelList()}
      <div className="finding-content-header-actions">
        {hasCreateItemRelationsPermission && (
          <>
            {!hasRelatedTicket ? (
              <ActionButton
                icon={SVG_ICON_TYPES.TICKET_ICON}
                onClick={handleCreateTicketsModalOpen}
                disabled={isCreatingTicket}
              >
                {translation('findings.ticket.createTicket')}
              </ActionButton>
            ) : (
              <DeleteWithConfirm
                callback={() => {}}
                id={codeEventData.findingId}
                setShouldIgnoreRowClick={null}
                useMutation={useBulkUnlinkTicketForFindingsMutation}
                title={translation('findings.ticket.unlinkTicket')}
                modalIcon={
                  <OpusSvgIcon type={SVG_ICON_TYPES.LINK_SLASH_ICON} />
                }
                message={translation('findings.ticket.unlinkTicketWarning')}
                confimButtonLabel={translation('findings.ticket.unlink')}
                loadingConfimButtonLabel={translation(
                  'findings.ticket.unlinking'
                )}
                customDelete={handleBulkUnlinkAction}
              >
                <ActionButton icon={SVG_ICON_TYPES.LINK_SLASH_ICON}>
                  <FormattedMessage
                    defaultMessage={'Unlink ticket'}
                    id="findings.ticket.unlinkTicket"
                  />
                </ActionButton>
              </DeleteWithConfirm>
            )}
          </>
        )}

        {renderCreatePRButton()}
        <ActionButton
          icon={SVG_ICON_TYPES.USER_ADD_ICON}
          onClick={handleAddOwner}
          disabled={!hasC2CWritePermission}
        >
          {translation(`findings.details.addOwner`)}
        </ActionButton>

        <Button
          className="add-label-button"
          onClick={(event) => {
            setLabelAnchorEl(event.currentTarget);
          }}
        >
          <div className="add-label-button-text">
            <OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />
            <span>{translation(`labels.title`)}</span>
          </div>
          <div className="add-label-button-end-andornment">
            <OpusSvgIcon type={SVG_ICON_TYPES.SORT_DESCENDING_ICON} />
          </div>
        </Button>
        <div className="updates-info-container">
          <ElementWithLabel
            label={translation(`findings.details.lastUpdated`)}
            icon={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON}
          >
            <Typography className="text-display">
              {moment(codeEventData.lastModified).format(dateTimeFormat)}
            </Typography>
          </ElementWithLabel>
          <ElementWithLabel
            label={translation(`findings.details.firstSeen`)}
            icon={SVG_ICON_TYPES.VISIBILITY_ICON}
          >
            <Typography className="text-display">
              {moment(codeEventData.lastSeen).format(dateTimeFormat)}
            </Typography>
          </ElementWithLabel>
        </div>
      </div>

      <FindingExceptionCreateModal
        isOpen={createExceptionModalOpen}
        handleClose={handleCreateExceptionModalClose}
      />
      {renderNotificationForOperation()}
      <PullRequestModal
        handleModalClose={() => {
          setIsPRModalOpen(false);
        }}
        isOpen={isPRModalOpen}
        response={pullRequestResponse}
        error={pullRequestError}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.GITHUB_ICON} />}
      />
      <TicketCreateModal
        handleModalClose={() => {
          setTicketModalConfiguration({
            ...ticketModalConfiguration,
            isOpen: false,
          });
        }}
        configuration={ticketModalConfiguration}
        handleBulkTicketCreation={handleBulkTicketCreation}
        ticketType={ticketModalConfiguration.ticketType}
        findingsCount={1}
      />
      <AddOwnerModal
        id={id}
        type={ItemType.CODE_EVENT}
        isOpen={isAddOwnerModalOpen}
        setIsOpen={setIsAddOwnerModalOpen}
      ></AddOwnerModal>

      <LabelManagementDropdown
        open={Boolean(labelAnchorEl)}
        handleClose={() => {
          setLabelAnchorEl(null);
        }}
        anchorEl={labelAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        addLabel={addLabel}
        displaySaveButton={false}
        filteredAllLabels={filteredAllLabels}
        filteredRecentLabels={filteredRecentLabels}
        loading={loading}
        onLabelSelect={selectLabel}
        refetch={refetch}
        saveLabels={() => {}}
        savingLabels={false}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedLabels={selectedLabels}
      />
    </div>
  );
};
