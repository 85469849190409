import { DisplayStepDetaislArgs } from 'FindingDetails/components/FindingWorkflow/FindingWorkflow';
import { NodeProps, NodeType } from './node';
import { rootWorkflowTriggerSeqId } from './types';
import { CrossroadConditionRoute } from 'WorkflowBuilder/components/CrossroadPanel/components/CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';

export enum DIAGRAM_STEP_TYPE {
  HTTP = 'http',
  CROSSROAD = 'crossroad',
  AWS = 'aws',
  INTERNAL = 'internal',
  HTTP_DUMMY = 'http_dummy',
  TRIGGER = 'trigger',
  CROSSROAD_DUMMY = 'crossroad_dummy',
  AZURE = 'azure',
  MICROSOFT_TEAMS = 'microsoft-teams',
  WIZ = 'wiz',
  LACEWORK = 'lacework',
  GCP = 'gcp',
  CHECKMARX = 'checkmarx',
  CROWDSTRIKE = 'crowdstrike',
  SNOWFLAKE = 'snowflake',
  JAMF = 'jamf',
}

export enum DIAGRAM_STEP_STATUS {
  FAILED = 'failed',
  DONE = 'done',
  IN_PROGRESS = 'inprogress',
  PENDING = 'pending',
}

export interface DiagramStepParameter {
  name?: string;
  displayName?: string;
  type?: string;
  value?: string;
}

// aka CrossroadConditionOperator
export enum Operator {
  EQUAL = 'equal',
  NOTEQUAL = 'notequal',
}

// aka CrossroadCondition
interface Condition {
  expressionA: string;
  expressionB: string;
  operator: Operator;
  name?: string;
}

// aka Route
export interface Route extends CrossroadConditionRoute {}

export enum CrossroadConditionOperator {
  EQUAL = 'equal',
  NOTEQUAL = 'notequal',
}

/**
 * aka StepDef
 */
export default interface DiagramStep {
  id?: string; // optional for newly created nodes
  name: string;
  readonly?: boolean;
  workflowSeqId: number;
  type?: DIAGRAM_STEP_TYPE;
  actionTemplateId?: string;
  applicationId?: string;
  connectionId?: string;
  icon?: string;
  parameters?: Array<DiagramStepParameter>;
  timelineEvent?: {
    status: string;
    value: string;
  };
  workFlowAffectOnFail?: string;
  diagramData: {
    id?: string;
    position: {
      x: number;
      y: number;
    };
    results?: any;
    status?: DIAGRAM_STEP_STATUS;
    icon?: string;
    onAddCrossroad?: (seqId: number) => void;
    displayCrossroadPanel?: (
      position: { top: number; left: number; bottom: number; right: number },
      stepId: number,
      readOnlyData: NodeProps
    ) => void;
    displayTriggerPanel?: () => void;
    handleEditNode?: (seqId: number) => void;
    isLeaf?: boolean;
    displayReadonlyStepDetails?: (args: DisplayStepDetaislArgs) => void;
    actionTemplateId?: string;
    applicationId?: string;
    params?: any;
    value?: Array<string> | string;
  };
  routes: Route[];
  nodeType?: NodeType;
  triggers?: string;
}

export function init(raw: any): DiagramStep {
  return {
    id: raw.id,
    name: raw.name ?? 'Step',
    readonly: raw.readonly,
    workflowSeqId: raw.workflowSeqId ?? rootWorkflowTriggerSeqId,
    diagramData: {
      position: {
        x: raw.diagramData?.position?.x ?? 0,
        y: raw.diagramData?.position?.y ?? 0,
      },
      icon: raw.diagramData.icon,
      isLeaf: raw.isLeaf,
      value: raw.diagramData?.value,
    },
    routes: raw.routes ?? [],
    nodeType: raw.nodeType ?? NodeType.HTTP,
  };
}

export function initBulk(raw: any[]): DiagramStep[] {
  return raw.map(init);
}
