import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { CustomFilterItem } from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';

const riskApi = createApi({
  reducerPath: 'riskApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['Risks'],
  endpoints: (builder) => ({
    searchRiskGroups: builder.mutation({
      query: (data) => ({
        url: `findings/risks/groups/title/search`,
        method: 'POST',
        body: data,
      }),
    }),
    searchResouceIdGroups: builder.mutation({
      query: (data) => ({
        url: `findings/risks/groups/resourceId/search`,
        method: 'POST',
        body: data,
      }),
    }),
    searchCodeOriginGroups: builder.mutation({
      query: (data) => ({
        url: `findings/risks/groups/codeOrigin/search`,
        method: 'POST',
        body: data,
      }),
    }),
    searchRootCauseGroups: builder.mutation({
      query: (data) => ({
        url: `findings/risks/groups/rootCause/search`,
        method: 'POST',
        body: data,
      }),
    }),
    createCampaign: builder.mutation({
      query: (data: any) => ({
        url: 'campaigns',
        method: 'POST',
        body: data,
      }),
    }),
    fetchFilterInformationForField: builder.mutation({
      query: (payload) => ({
        url: `config/filters/${payload.field}`,
        method: 'POST',
      }),
    }),
    bulkCreateTicketForFindings: builder.mutation({
      query: (body) => ({
        url: `findings/actions/${body.ticketApplication}/bulk`,
        method: 'POST',
        body,
      }),
    }),
    bulkUnlinkTicketForFindings: builder.mutation({
      query: (body) => ({
        url: `findings/actions/unlink/bulk`,
        method: 'POST',
        body,
      }),
    }),
    bulkCreatePullRequestForFindings: builder.mutation({
      query: (body) => ({
        url: `iacs/events/actions/pullrequest/bulk`,
        method: 'POST',
        body,
      }),
    }),
    bulkFindingActionByType: builder.mutation({
      query: (payload) => ({
        url: `findings/${payload.type}/bulk`,
        method: 'POST',
        body: payload.body,
      }),
    }),
    createCustomFilter: builder.mutation({
      query: (body) => ({
        url: `custom-filters`,
        method: 'POST',
        body: body,
      }),
      transformResponse: async (response) => {
        return response as CustomFilterItem;
      },
    }),
    fetchCustomFilter: builder.mutation({
      query: (body) => ({
        url: `custom-filters/search`,
        method: 'POST',
        body: body,
      }),
      transformResponse: async (response) => {
        return response as CustomFilterItem[];
      },
    }),
    deleteCustomFilter: builder.mutation({
      query: (id) => ({
        url: `custom-filters/${id}`,
        method: 'DELETE',
      }),
    }),
    searchResourceAssets: builder.mutation({
      query: ({ type, resourceExternalId }) => ({
        url: `assets/search/${type}`,
        method: 'POST',
        body: {
          resourceExternalId,
        },
      }),
    }),
    uploadImportFindingsFile: builder.mutation({
      query: (payload) => ({
        url: `findings/import-findings`,
        method: 'POST',
        body: payload,
        headers: { 'content-type': 'multipart/form-data' },
      }),
    }),
    generateFilter: builder.mutation({
      query: (message) => ({
        url: `findings/generate-filter${
          message.length ? `?message=${message}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export default riskApi;

export const {
  useSearchRiskGroupsMutation,
  useCreateCampaignMutation,
  useFetchFilterInformationForFieldMutation,
  useBulkCreatePullRequestForFindingsMutation,
  useBulkCreateTicketForFindingsMutation,
  useSearchResouceIdGroupsMutation,
  useSearchCodeOriginGroupsMutation,
  useSearchRootCauseGroupsMutation,
  useBulkUnlinkTicketForFindingsMutation,
  useBulkFindingActionByTypeMutation,
  useCreateCustomFilterMutation,
  useFetchCustomFilterMutation,
  useDeleteCustomFilterMutation,
  useSearchResourceAssetsMutation,
  useUploadImportFindingsFileMutation,
  useGenerateFilterMutation,
} = riskApi;
