import { useMemo } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalWidgetDataTrend,
  OperationalWidgetDataTrendImpact,
} from 'shared/models/data/operational-widget-data.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface TrendChipProps {
  trendValue: number;
  title?: string;
  trendImpact: OperationalWidgetDataTrendImpact;
  trend: OperationalWidgetDataTrend;
}

export const TrendChip: React.FC<TrendChipProps> = ({
  trendValue,
  title,
  trendImpact,
  trend,
}) => {
  const getTrendImpactClassName = (
    trendImpact: OperationalWidgetDataTrendImpact
  ): string => {
    switch (trendImpact) {
      case OperationalWidgetDataTrendImpact.NEGATIVE:
        return 'negative-trend';
      case OperationalWidgetDataTrendImpact.POSITIVE:
        return 'positive-trend';
      case OperationalWidgetDataTrendImpact.NEUTRAL:
        return 'neutral-trend';
      default:
        return '';
    }
  };

  const getTrendIcon = () => {
    switch (trend) {
      case OperationalWidgetDataTrend.INCREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_ICON} />;
      case OperationalWidgetDataTrend.DECREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_DOWN_ICON} />;
      case OperationalWidgetDataTrend.SAME:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`trend-chip-container ${getTrendImpactClassName(trendImpact)}`}
      title={title}
    >
      {getTrendIcon()}
      <span className="label-5">{trendValue}</span>
    </div>
  );
};

export default TrendChip;
