export enum ApplicationTypes {
  SENTINEL_ONE = 'SentinelOne',
  PANW_CORTEX = 'PANWCortexXDR',
  DEVO = 'Devo',
  EXABEAM = 'Exabeam',
  HUNTERS = 'Hunters',
  SECURONIX = 'Securonix',
  GCP = 'GCP',
  CARBON_BLACK = 'CarbonBlack',
  MS_AZURE_SENTINE = 'MSAzureSentinel',
  SPLUNK = 'Splunk',
  PANW_PRISMA = 'PANWPrisma',
  SOPHOS = 'Sophos',
  LACEWORK = 'Lacework',
  ERMETIC = 'Ermetic',
  CROWD_STRIKE = 'CrowdStrikeFlacon',
  ORCA = 'Orca',
  MS_SECURITY_CENTER = 'MSSecurityCenter',
  WIZ = 'Wiz',
  AQUA = 'Aqua',
  AWS_CONFIG = 'AWSConfig',
  AWS_S3 = 'AWSS3',
  AWS_CLOUD_TRAIL = 'AWSCloudTrail',
  CLOUD_GUARD = 'CloudGuard',
  GCP_COMMAND_CENTER = 'GCPCommandCenter',
  AWS_EC2 = 'AWSEC2',
  ONE_LOGIN = 'OneLogin',
  AIRTABLE = 'Airtable',
  AWS_GUARD_DUTY = 'AWSGuardDuty',
  OPUS = 'Opus',
  OKTA_WORKFORCE = 'OktaWorkforce',
  JIRA_CLOUD_PLATFORM = 'JiraCloudPlatform',
  JIRA_SERVER_PLATFORM = 'JiraServerPlatform',
  AZURE_DEVOPS = 'AzureDevops',
  GITHUB = 'Github',
  GITHUB_ADVANCED_SECURITY = 'GitHubAdvancedSecurity',
  GITLAB = 'Gitlab',
  AWSIAM = 'AWSIAM',
  AWS_SECURITY_HUB = 'AWSSecurityHub',
  SLACK = 'Slack',
  AWSRDS = 'AWSRDS',
  SECURITY_HUB = 'SecurityHub',
  AWS = 'AWS',
  MICROSOFT_TEAMS = 'MicrosoftTeams',
  CROWDSTRIKE = 'CrowdStrike',
  CHECKMARX = 'CheckMarx',
  SNOWFLAKE = 'Snowflake',
  JAMF = 'Jamf',
}
