import { DisplayStepDetaislArgs } from 'FindingDetails/components/FindingWorkflow/FindingWorkflow';
import { Node } from 'react-flow-renderer';
import { DIAGRAM_STEP_STATUS } from './step';

export enum NodeType {
  HTTP = 'http',
  CROSSROAD = 'crossroad',
  TRIGGER = 'trigger',
  INTERNAL = 'internal',
  AWS = 'aws',
  PLUS = 'plus',
  TOOL = 'tool',
  DOT = 'dot',
  AZURE = 'azure',
  MICROSOFT_TEAMS = 'microsoft-teams',
  WIZ = 'wiz',
  LACEWORK = 'lacework',
  GCP = 'gcp',
  CHECKMARX = 'checkmarx',
  CROWDSTRIKE = 'crowdstrike',
  SNOWFLAKE = 'snowflake',
  JAMF = 'jamf',
}

export const stepTypesWithNoPayload: Array<NodeType> = [
  NodeType.TRIGGER,
  NodeType.CROSSROAD,
  NodeType.PLUS,
  NodeType.TOOL,
  NodeType.DOT,
];

export interface NodeProps {
  id?: string;
  label: string;
  icon: string;
  isLeaf: boolean;
  seq: number;
  onAddCrossroad?: (node: any) => void;
  displayCrossroadPanel?: (
    position: { top: number; left: number; bottom: number; right: number },
    stepId: number,
    readOnlyData: NodeProps
  ) => void;
  handleEditNode?: (seqId: number) => void;
  displayReadonlyStepDetails?: (args: DisplayStepDetaislArgs) => void;
  displayTriggerPanel?: () => void;
  readonly?: boolean;
  results?: any;
  status?: DIAGRAM_STEP_STATUS;
  applicationId?: string;
  actionTemplateId?: string;
  params?: any;
  value?: Array<string> | string;
}

export default interface DiagramNode extends Node<NodeProps> {}
