import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosResponse } from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import commonConfig from 'Config';
import authConstants from 'Auth/constants';
import { createWriteStream } from 'streamsaver';
import { ReadableStream } from 'web-streams-polyfill';

const api = axios.create({});

export interface AxiosBaseQueryError {
  status: string;
  data: any;
}

const defaultSecurityHeaders = {
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'X-XSS-Protection': '1; mode=block',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
  'Content-Security-Policy': `default-src 'self'; base-uri 'self'; frame-ancestors 'none'; upgrade-insecure-requests; font-src 'self' https: data:; form-action 'self'; img-src 'self' data:; object-src 'none'; script-src 'self'; script-src-attr 'none'; style-src 'self' https: 'unsafe-inline'; connect-src 'self'`,
};

api.interceptors.request.use((requestConfig: any) => {
  // if (window.location?.href?.includes(machineRoutePrefix)) {
  //   return requestConfig;
  // }

  const accessToken = localStorage.getItem(authConstants.authAccessToken);
  requestConfig.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...requestConfig.headers,
    ...defaultSecurityHeaders,
  };

  return requestConfig;
});

api.interceptors.response.use((responseConfig: AxiosResponse<any>) => {
  const contentDisposition = responseConfig.headers['content-disposition'];

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
    const filename = filenameMatch ? filenameMatch[1] : 'Opus.csv';

    const encoder = new TextEncoder();
    const csvDataEncoded = encoder.encode(responseConfig.data);

    const responseStream = new ReadableStream<Uint8Array>({
      start(controller) {
        controller.enqueue(csvDataEncoded);
        controller.close();
      },
    });

    const fileStream = createWriteStream(filename);

    const writer = fileStream.getWriter();
    const reader = responseStream.getReader();

    const pump = async (): Promise<void> => {
      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            writer.close();
            break;
          }
          await writer.write(value);
        }
      } catch (error) {
        console.error('Error while pumping data:', error);
        writer.abort();
      }
    };

    pump();
  }

  return responseConfig;
});

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    | {
        url: string;
        method: AxiosRequestConfig['method'];
        body?: AxiosRequestConfig['data'];
        params?: AxiosRequestConfig['params'];
        headers?: AxiosRequestConfig['headers'];
      }
    | string,
    unknown,
    unknown
  > =>
  async (args) => {
    try {
      let requestUrl =
        typeof args === 'string'
          ? baseUrl + '/' + args
          : baseUrl + '/' + args.url;

      await api.options(requestUrl);

      const result = await api(
        typeof args === 'string'
          ? { url: baseUrl + '/' + args, method: 'GET' }
          : {
              url: baseUrl + '/' + args.url,
              method: args.method,
              data: args.body,
              params: args.params,
              headers: args.headers,
            }
      );
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default function axiosFetchBaseQuery(configuration?: {
  baseUrl: string;
}) {
  return axiosBaseQuery({
    baseUrl: configuration?.baseUrl || commonConfig.globalGwBaseUrl,
  });
}
