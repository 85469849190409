export const formatDate = (date: Date, extended = true) => {
  const dt = new Date(date || Date.now());
  const options: Intl.DateTimeFormatOptions = extended
    ? {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
    : { month: '2-digit', day: '2-digit', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(dt);
};

export const convertMarkdownToTextWithLink = (text: string) => {
  const textWithLink = text.replace(
    /\[([^\]]+)\]\(([^\)]+)\)/,
    '<a href="$2" target="_blank">$1</a>'
  );
  return textWithLink;
};

export function subtractMonths(numOfMonths: number, date = new Date()) {
  date.setMonth(date.getMonth() - numOfMonths);
  return date.toISOString();
}

export enum substractType {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
}

export function subtractDate(
  type: string,
  number: number = 1,
  date = new Date()
) {
  switch (type) {
    case substractType.WEEK:
      date.setDate(date.getDate() - number * 7);
      break;
    case substractType.MONTH:
      date.setMonth(date.getMonth() - number);
      break;
    case substractType.YEAR:
      date.setFullYear(date.getFullYear() - number);
      break;
    default:
      return date.toISOString();
  }
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  return date.toISOString();
}

export function secondsFormatter(s: number) {
  const d = Math.floor(s / (3600 * 24));

  s -= d * 3600 * 24;

  const h = Math.floor(s / 3600);

  s -= h * 3600;

  const m = Math.floor(s / 60);

  s -= m * 60;

  const tmp = [];

  d && tmp.push(d + ' D');

  (d || h) && tmp.push(h + ' H');

  (d || h || m) && tmp.push(m + ' M');

  return tmp.join(' ');
}
