export enum OperationalMetricType {
  NEW_FINDINGS = 'total_new_finding',
  NEW_RESOLVED_FINDINGS = 'resolved_findings',
  NEW_RESOLVED_WITHIN_SLA = 'resolved_within_sla',
  NEW_RESOLVED_OUTSIDE_SLA = 'resolved_outside_sla',
  NEW_EXCEPTION_APPROVED = 'exceptions_approvals',
  SLA_ATTAINMENT_RATE = 'sla',
  TRENDING_MTTR = 'average_mttr',
  NEW_FINDINGS_BREACHED_SLA = 'new_outside_sla',
  TOTAL_ACTIVE_FINDINGS = 'total_active_findings',
  TOTAL_CRITICAL_FINDINGS = 'total_critical_findings',
  TOTAL_EXPLOITABLE_FINDINGS = 'total_exploitable_findings',
  TOTAL_ACTIVE_WITHIN_SLA = 'total_active_within_sla',
  TOTAL_ACTIVE_OUTSIDE_SLA = 'total_active_outside_sla',
  TOTAL_MTTR = 'total_mttr',
}

export enum OperationalWidgetAnalyticsType {
  TOTAL_NEW_FINDINGS = 'total_new_finding',
  ROOT_CAUSE = 'root_cause',
  AGGREGATED_RISK = 'aggregated_risk',
  RESOLVED_FINDINGS = 'resolved_findings',
  AVERAGE_MTTR = 'average_mttr',
  SLA = 'sla',
  NEW_FINDINGS_BY_SEVERITY = 'new_findings_by_severity',
  NEW_FINDINGS_BY_CATEGORY = 'new_findings_by_category',
  FINDING_BY_TICKET_AGE = 'finding_by_ticket_age',
  FINDING_BY_SLA_BREACHED_AGE = 'finding_by_sla_breached_age',
  NEW_FINDINGS_BY_ASSIGNEE = 'new_findings_by_assignee',
  NEW_FINDINGS_BY_STATUS = 'new_findings_by_status',
  NEW_FINDINGS_BY_TICKET_STATUS = 'new_findings_by_ticket_status',
  EXCEPTIONS_REQUESTS = 'exceptions_requests',
  EXCEPTIONS_APPROVALS = 'exceptions_approvals',
  SLA_RATE = 'sla_rate',
  NEW_WITHIN_SLA = 'new_within_sla',
  NEW_OUTSIDE_SLA = 'new_outside_sla',
  RESOLVED_WITHIN_SLA = 'resolved_within_sla',
  RESOLVED_OUTSIDE_SLA = 'resolved_outside_sla',

  ACCUMULATIVE_OPEN_FINDINGS_BY_STATUS = 'accumulative_open_findings_by_status',
  ACCUMULATIVE_OPEN_FINDINGS_BY_ASSIGNEE = 'accumulative_open_findings_by_assignee',
  ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_AGE = 'accumulative_open_findings_by_ticket_age',
  ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_STATUS = 'accumulative_open_findings_by_ticket_status',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE = 'accumulative_open_findings_by_sla_breach_age',
}

export enum PostureWidgetAnalyticsType {
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_STATUS = 'accumulative_open_findings_by_sla_status',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE = 'accumulative_open_findings_by_sla_breach_age',
  ACCUMULATIVE_OPEN_FINDINGS_BY_SEVERITY = 'accumulative_open_findings_by_severity',
  ACCUMULATIVE_OPEN_FINDINGS = 'accumulative_open_findings',
  ACCUMULATIVE_RESOLVED_FINDINGS = 'accumulative_resolved_findings',
  ACCUMULATIVE_RISKS = 'accumulative_risks',
  ACCUMULATIVE_ROOT_CAUSES = 'accumulative_root_causes',
  ACCUMULATIVE_PENDING_EXCEPTIONS = 'accumulative_pending_exceptions',
  ACCUMULATIVE_ACTIVE_EXCEPTIONS = 'accumulative_active_exceptions',
  ACCUMULATIVE_SLA = 'accumulative_sla',
  ACCUMULATIVE_AVERAGE_MTTR = 'accumulative_average_mttr',
  ACCUMULATIVE_FINDINGS_BY_SEVERITY = 'accumulative_findings_by_severity',
  ACCUMULATIVE_FINDINGS_BY_CATEGORY = 'accumulative_findings_by_category',
  ACCUMULATIVE_SDLC_ONPREM = 'accumulative_sdlc_onprem',
  ACCUMULATIVE_SDLC_CODE = 'accumulative_sdlc_code',
  ACCUMULATIVE_SDLC_ARTIFACT = 'accumulative_sdlc_artifact',
  ACCUMULATIVE_SDLC_RUNTIME = 'accumulative_sdlc_runtime',
  ACCUMULATIVE_RISK_SURFACE_CLOUD_MISCONFIGURATION = 'accumulative_risk_surface_cloud_misconfiguration',
  ACCUMULATIVE_RISK_SURFACE_CODE_VULNERABILITIES = 'accumulative_risk_surface_code_vulnerabilities',
  ACCUMULATIVE_RISK_SURFACE_ONPREM_VULNERABILITIES = 'accumulative_risk_surface_onprem_vulnerabilities',
  ACCUMULATIVE_RISK_SURFACE_VULNERABILITY = 'accumulative_risk_surface_vulnerabilities',
  ACCUMULATIVE_RISK_SURFACE_APP_VULNERABILITY = 'accumulative_risk_surface_app_vulnerabilities',
  SCORECARD_PER_DOMAIN = 'scorecard_per_domain',
}

export enum OperationalWidgetDataTrend {
  INCREASING = 'increasing',
  DECREASING = 'decreasing',
  SAME = 'same',
}

export enum OperationalWidgetDataTrendImpact {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
}

export interface OperationalWidgetData {
  value: number | string;
  trend: OperationalWidgetDataTrend;
  trendPercentage: number;
  trendImpact: OperationalWidgetDataTrendImpact;
}
