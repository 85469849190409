import {
  GridRowSelectionProps,
  GridSelectionChild,
  GridSelectionProps,
  GridSelectionType,
  GridType,
} from 'Risk/store';

export interface GridSelectionPayload {
  responsePayload: any;
  currentGridSelectionProps: GridSelectionProps;
  selection: GridSelectionType;
  selectionId: string;
  nestedSelectionId: string;
  rowCount: number;
  selectionIds?: Array<string>;
}

export interface IGridSelectionHandler {
  handleGridSelectionStateOnSearchParamsChange: (
    payload: Pick<
      GridSelectionPayload,
      'responsePayload' | 'currentGridSelectionProps'
    >
  ) => GridSelectionProps;
  handleGridSelectionChanges: (
    payload: Pick<
      GridSelectionPayload,
      'currentGridSelectionProps' | 'responsePayload'
    >
  ) => GridSelectionProps;
  handleNestedGridRowSelection: (
    payload: Pick<
      GridSelectionPayload,
      'currentGridSelectionProps' | 'selectionId' | 'nestedSelectionId'
    >
  ) => GridSelectionProps;
  handleNestedGridSelectionStateOnSearchParamsChange: (
    payload: Pick<
      GridSelectionPayload,
      'responsePayload' | 'currentGridSelectionProps' | 'selectionId'
    >
  ) => GridSelectionProps;
}

export class GridSelectionHandler implements GridSelectionHandler {
  handleGridSelectionStateOnSearchParamsChange(
    payload: Pick<
      GridSelectionPayload,
      'responsePayload' | 'currentGridSelectionProps'
    >,
    gridType?: GridType
  ): GridSelectionProps {
    const { currentGridSelectionProps, responsePayload } = payload;

    const availableItems: Array<GridSelectionChild> =
      responsePayload?.data?.map((dataItem: any) => ({
        id:
          gridType && gridType === GridType.Risk
            ? dataItem.findingTitle
            : dataItem.aggregatedValue,
        rowCount: dataItem.totalCountForAggregatedGroup,
      }));

    const uniqueAvailableItems: Array<GridSelectionChild> = (
      [
        ...availableItems,
        ...currentGridSelectionProps.availableChildren,
      ] as Array<GridSelectionChild>
    ).filter((childItem, index, itemArray) => {
      return (
        itemArray.findIndex(
          (item: GridSelectionChild) => item.id == childItem.id
        ) === index
      );
    });

    const totalAvailableChildren: number = responsePayload?.data?.reduce(
      (accumulator: number, currentValue: any) =>
        accumulator + currentValue.totalCountForAggregatedGroup,
      0
    );

    return {
      ...currentGridSelectionProps,
      availableChildren: uniqueAvailableItems,
      totalAvailableChildren: totalAvailableChildren,
    };
  }

  handleGridSelectionChanges(
    payload: Pick<
      GridSelectionPayload,
      'currentGridSelectionProps' | 'responsePayload' | 'selectionIds'
    >
  ): GridSelectionProps {
    const { currentGridSelectionProps, selectionIds } = payload;

    const removedItems = currentGridSelectionProps.selectedChildren.filter(
      (selectedChild: string | number) =>
        !selectionIds?.includes(selectedChild as string)
    );

    const addedItems = (selectionIds as Array<string>).filter(
      (selectedChild: string | number) =>
        !currentGridSelectionProps.selectedChildren.includes(
          selectedChild as string
        )
    );

    const selectionCount: number = selectionIds?.reduce(
      (accumulator, currentValue) => {
        const existingRowEntry = currentGridSelectionProps.rows?.find(
          (rowItem) => rowItem.value === currentValue
        );

        const rowTotalCount =
          (
            currentGridSelectionProps.availableChildren.find(
              (dataItem: any) => dataItem.id === currentValue
            ) as GridSelectionChild
          )?.rowCount || 0;

        const addedCount =
          existingRowEntry &&
          existingRowEntry.selection === GridSelectionType.UNSELECTED
            ? rowTotalCount
            : addedItems.includes(currentValue)
            ? rowTotalCount
            : (existingRowEntry?.totalSelectedChildren as number) ||
              rowTotalCount;

        return (accumulator + addedCount) as number;
      },
      0
    ) as number;

    return {
      ...currentGridSelectionProps,
      selectedChildren: selectionIds as Array<string>,
      totalSelectedChildren: selectionCount,
      rows: currentGridSelectionProps.rows?.map((rowItem) => {
        if (addedItems?.includes(rowItem.value as string)) {
          return {
            ...rowItem,
            selection: GridSelectionType.ALL,
            unselectedChildren: [],
            totalSelectedChildren: rowItem.totalAvailableChildren,
          };
        }

        if (removedItems.includes(rowItem.value as string)) {
          return {
            ...rowItem,
            selection: GridSelectionType.UNSELECTED,
            unselectedChildren: rowItem.availableChildren,
            totalSelectedChildren: 0,
          };
        }

        return rowItem;
      }),
    };
  }

  handleNestedGridRowSelection(
    payload: Pick<
      GridSelectionPayload,
      'currentGridSelectionProps' | 'selectionId' | 'nestedSelectionId'
    >
  ): GridSelectionProps {
    const { currentGridSelectionProps, selectionId, nestedSelectionId } =
      payload;

    const parentEntry = currentGridSelectionProps.rows?.find(
      (row) => row.value === selectionId
    );

    if (parentEntry?.selection === GridSelectionType.ALL) {
      return {
        ...currentGridSelectionProps,
        selectedChildren:
          parentEntry.totalAvailableChildren === 1
            ? currentGridSelectionProps.selectedChildren.filter(
                (selection) => selection !== selectionId
              )
            : currentGridSelectionProps.selectedChildren,
        rows: currentGridSelectionProps?.rows?.map((rowItem) => {
          if (rowItem.value === selectionId) {
            return {
              ...rowItem,
              selection:
                parentEntry.totalAvailableChildren === 1
                  ? GridSelectionType.UNSELECTED
                  : GridSelectionType.PARTIAL,
              unselectedChildren: [nestedSelectionId],
              totalSelectedChildren: parentEntry.totalSelectedChildren - 1,
            };
          }
          return rowItem;
        }),
        totalSelectedChildren:
          currentGridSelectionProps.totalSelectedChildren - 1,
      };
    }

    if (parentEntry?.selection === GridSelectionType.UNSELECTED) {
      return {
        ...currentGridSelectionProps,
        selectedChildren: [
          ...currentGridSelectionProps.selectedChildren,
          selectionId,
        ].filter((item, index, array) => array.indexOf(item) === index),
        rows: currentGridSelectionProps?.rows?.map((rowItem) => {
          if (rowItem.value === selectionId) {
            return {
              ...rowItem,
              selection:
                rowItem.totalAvailableChildren === 1
                  ? GridSelectionType.ALL
                  : GridSelectionType.MANUAL,
              unselectedChildren: parentEntry.availableChildren.filter(
                (child) => child !== nestedSelectionId
              ),
              totalSelectedChildren: 1,
            };
          }
          return rowItem;
        }),
        totalSelectedChildren:
          currentGridSelectionProps.totalSelectedChildren + 1,
      };
    }

    if (parentEntry?.selection === GridSelectionType.MANUAL) {
      const isSelected =
        !parentEntry?.unselectedChildren.includes(nestedSelectionId);

      const shouldActivateAll =
        !isSelected &&
        (parentEntry?.totalSelectedChildren as number) + 1 ===
          parentEntry?.totalAvailableChildren;

      const rowSelection = shouldActivateAll
        ? GridSelectionType.ALL
        : isSelected &&
          parentEntry?.unselectedChildren.length + 1 ===
            parentEntry.availableChildren?.length
        ? GridSelectionType.UNSELECTED
        : GridSelectionType.MANUAL;

      return {
        ...currentGridSelectionProps,
        rows: currentGridSelectionProps.rows?.map((rowItem) => {
          if (rowItem?.value === selectionId) {
            return {
              ...rowItem,
              selection: rowSelection,
              unselectedChildren: isSelected
                ? [
                    ...(parentEntry?.unselectedChildren || []),
                    nestedSelectionId,
                  ]
                : parentEntry.unselectedChildren.filter(
                    (child) => child !== nestedSelectionId
                  ),
              totalSelectedChildren: isSelected
                ? (parentEntry?.totalSelectedChildren as number) - 1
                : (parentEntry?.totalSelectedChildren as number) + 1,
            };
          }

          return rowItem;
        }),
        selectedChildren: isSelected
          ? parentEntry.totalSelectedChildren === 1
            ? currentGridSelectionProps.selectedChildren.filter(
                (childItem) => childItem !== selectionId
              )
            : currentGridSelectionProps.selectedChildren
          : [...currentGridSelectionProps.selectedChildren, selectionId].filter(
              (item, index, array) => array.indexOf(item) === index
            ),
        totalSelectedChildren: isSelected
          ? currentGridSelectionProps.totalSelectedChildren - 1
          : currentGridSelectionProps.totalSelectedChildren + 1,
      };
    }

    if (parentEntry?.selection === GridSelectionType.PARTIAL) {
      const isSelected =
        !parentEntry?.unselectedChildren.includes(nestedSelectionId);

      const shouldActivateAll =
        !isSelected &&
        (parentEntry?.totalSelectedChildren as number) + 1 ===
          parentEntry?.totalAvailableChildren;

      const rowSelection = shouldActivateAll
        ? GridSelectionType.ALL
        : isSelected &&
          parentEntry?.unselectedChildren.length + 1 ===
            parentEntry.availableChildren?.length
        ? GridSelectionType.UNSELECTED
        : GridSelectionType.PARTIAL;

      return {
        ...currentGridSelectionProps,
        rows: currentGridSelectionProps.rows?.map((rowItem) => {
          if (rowItem?.value === selectionId) {
            return {
              ...rowItem,
              selection: rowSelection,
              unselectedChildren: isSelected
                ? [
                    ...(parentEntry?.unselectedChildren || []),
                    nestedSelectionId,
                  ]
                : parentEntry.unselectedChildren.filter(
                    (child) => child !== nestedSelectionId
                  ),
              totalSelectedChildren: isSelected
                ? (parentEntry?.totalSelectedChildren as number) - 1
                : (parentEntry?.totalSelectedChildren as number) + 1,
            };
          }

          return rowItem;
        }),
        selectedChildren: isSelected
          ? parentEntry.totalSelectedChildren === 1
            ? currentGridSelectionProps.selectedChildren.filter(
                (childItem) => childItem !== selectionId
              )
            : currentGridSelectionProps.selectedChildren
          : [...currentGridSelectionProps.selectedChildren, selectionId].filter(
              (item, index, array) => array.indexOf(item) === index
            ),
        totalSelectedChildren: isSelected
          ? currentGridSelectionProps.totalSelectedChildren - 1
          : currentGridSelectionProps.totalSelectedChildren + 1,
      };
    }

    return currentGridSelectionProps;
  }

  handleNestedGridSelectionStateOnSearchParamsChange(
    payload: Pick<
      GridSelectionPayload,
      'responsePayload' | 'currentGridSelectionProps' | 'selectionId'
    >
  ): GridSelectionProps {
    const { responsePayload, currentGridSelectionProps, selectionId } = payload;

    const isParentSelected =
      currentGridSelectionProps.selectedChildren.includes(selectionId);

    const parentEntry = currentGridSelectionProps.rows?.find(
      (row) => row.value === selectionId
    );

    const availableChildren = responsePayload.data.map(
      (dataItem: any) => dataItem?.findingId
    );

    if (!isParentSelected && !parentEntry) {
      return {
        ...currentGridSelectionProps,
        rows: [
          ...(currentGridSelectionProps.rows || []),
          {
            value: selectionId,
            selection: GridSelectionType.UNSELECTED,
            totalAvailableChildren: responsePayload?.totalItems,
            unselectedChildren: availableChildren,
            totalSelectedChildren: 0,
            availableChildren,
          },
        ],
      };
    }

    if (isParentSelected && !parentEntry) {
      return {
        ...currentGridSelectionProps,
        rows: [
          ...(currentGridSelectionProps.rows || []),
          {
            value: selectionId,
            selection: GridSelectionType.ALL,
            totalAvailableChildren: responsePayload?.totalItems,
            availableChildren,
            unselectedChildren: [],
            totalSelectedChildren: responsePayload?.totalItems,
          },
        ],
      };
    }

    const items: Array<string> = responsePayload?.data?.map(
      (dataItem: any) => dataItem?.findingId
    );

    const updatedAvailableChildren = [
      ...(parentEntry?.availableChildren || []),
      ...items,
    ].filter((item, index, array) => array.indexOf(item) === index);

    let unselectedChildren: Array<string | number> = [];

    if (parentEntry?.selection === 'unselected') {
      unselectedChildren = updatedAvailableChildren;
    }

    if (parentEntry?.selection === 'manual') {
      unselectedChildren = [
        ...updatedAvailableChildren.filter(
          (child) => !parentEntry.availableChildren?.includes(child)
        ),
        ...parentEntry.unselectedChildren,
      ].filter((item, index, array) => array.indexOf(item) === index);
    }

    if (parentEntry?.selection === 'partial') {
      unselectedChildren = parentEntry?.unselectedChildren || [];
    }
    return {
      ...currentGridSelectionProps,
      rows: currentGridSelectionProps.rows?.map(
        (rowItem: GridRowSelectionProps) => {
          if (rowItem.value === selectionId) {
            return {
              ...rowItem,
              unselectedChildren,
              availableChildren: updatedAvailableChildren,
            };
          }

          return rowItem;
        }
      ),
    };
  }
}
