import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface ScoreChipProps extends BaseComponentProps {
  score: number;
  bordered?: boolean;
}

export const ScoreChip: FunctionComponent<ScoreChipProps> = ({
  score,
  bordered,
}) => {
  const getScoreClassname = (score: number) => {
    if (score < 4) {
      return 'score-chip-critical';
    }

    if (score >= 4 && score < 7) {
      return 'score-chip-high';
    }
    if (score >= 7 && score < 9) {
      return 'score-chip-medium';
    }

    return 'score-chip-low';
  };
  return (
    <div
      className={`score-chip ${getScoreClassname(score)} ${
        bordered ? 'bordered' : ''
      }`}
    >
      {score}
    </div>
  );
};
export default ScoreChip;
