import {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  startTransition,
  useContext,
} from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import OpusList from '../../../shared/components/OpusList';
import OpusSvgIcon from '../../../shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ListItem } from 'shared/models/props/list-props.model';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import {
  useGetSensorTriggerOptionsMutation,
  useGetSensorTriggersMutation,
} from 'Flow/store/api';
import Slider from '../Slider';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';
import List from '../List';
import { IListItem } from '../List/List';
import CommonCheckbox from 'shared/components/CommonCheckbox';
import {
  WorkflowFindingContext,
  WorkflowFindingContextProps,
  WorkflowTriggerDto,
} from '../Layout/Layout';

interface WorkflowTriggerPanelProps {
  children?: any;
  display: boolean;
  onClose: () => void;
  onSave: (values: Record<string, Array<string>>) => void;
  triggerList?: Array<string>;
  triggerListByApp?: Record<string, Array<any>>;
  selectedTriggers?: Array<WorkflowTriggerDto>;
}

export function WorkflowTriggerPanel({
  display,
  onClose,
  onSave,
  triggerList = [],
  triggerListByApp = {},
  selectedTriggers,
}: WorkflowTriggerPanelProps) {
  const { t: translation } = useTranslation();

  const displayValue = display ? 'block' : 'none';

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const [sensorSearchValue, setSenorSearchValue] = useState<string>('');

  const [findingSearchValue, setFindingSearchValue] = useState<string>('');

  const { setActiveFindingId } = useContext<WorkflowFindingContextProps>(
    WorkflowFindingContext
  );

  const [currentActiveFindingId, setCurrentActiveFindingId] = useState<
    string | null
  >(null);

  const [
    getSensorTriggers,
    { data: sensorTriggers, isLoading: sensorTriggersLoading },
  ] = useGetSensorTriggersMutation();

  const [searchFindings, { data: findingData, isLoading: findingDataLoading }] =
    useFetchFindingsWithResourceMutation();

  const [panelConfiguration, setPanelConfiguration] = useState<{
    searchValue: string;
    preCheckedOptions: any;
    preOpenPanels: any;
  }>({ searchValue: '', preCheckedOptions: {}, preOpenPanels: {} });

  const [checkedValues, setCheckedValues] = useState<Array<string> | null>(
    null
  );

  const onSelectFinding = (findingId: string) => {
    setCurrentActiveFindingId(findingId);
  };

  const sensorTriggersListItems = useMemo<Array<ListItem>>(() => {
    return sensorTriggers?.options
      ? sensorTriggers?.options?.map((option: any) => ({
          ...option,
          display: true,
          icon: option.logo,
          getChildrenMutation: useGetSensorTriggerOptionsMutation,
          childrenRequestPayload: {
            applicationId: option.id,
          },
          selectedCounter:
            panelConfiguration.preCheckedOptions[option.id]?.length,
        }))
      : [];
  }, [sensorTriggers, panelConfiguration.preCheckedOptions]);

  const findingListItems = useMemo<Array<IListItem>>(() => {
    return findingData?.data?.length
      ? findingData?.data?.map((finding: FindingsWithResource) => ({
          id: finding.findingId,
          label: finding.title,
          subLabel: finding.type,
          onClick: () => {},
          imageMetadata: {
            alt: finding.findingSource,
            src: finding.sourceAppLogoUrl as string,
          },
        }))
      : [];
  }, [findingData]);

  useEffect(() => {
    return () => {
      setSenorSearchValue('');
      setFindingSearchValue('');
      setActiveSlideIndex(0);
    };
  }, []);

  useEffect(() => {
    if (displayValue === 'block') {
      getSensorTriggers('');
    }
  }, [displayValue]);

  useEffect(() => {
    if (activeSlideIndex === 1) {
      const findingTypes = Object.values(
        panelConfiguration.preCheckedOptions
      ).flat() as Array<string>;

      searchFindings({
        filter: {
          type: findingTypes.map((type: string) => ({
            value: type,
          })),
          title: { value: findingSearchValue },
        },
      });
    }
  }, [activeSlideIndex, findingSearchValue]);

  const saveCheckedValues = (
    values: Array<string>,
    rawOptions: any,
    openPanels: any
  ) => {
    setCheckedValues(compact(values));
    setPanelConfiguration({
      ...panelConfiguration,
      preCheckedOptions: rawOptions,
      preOpenPanels: openPanels,
    });
  };

  useEffect(() => {
    if (!checkedValues && triggerList.length > 0) {
      setCheckedValues(triggerList);
      setPanelConfiguration({
        ...panelConfiguration,
        preCheckedOptions: triggerListByApp
          ? {
              ...panelConfiguration.preCheckedOptions,
              ...Object.keys(triggerListByApp)?.reduce(
                (accumulator: any, currentValue: string) => {
                  accumulator[currentValue] = triggerListByApp[
                    currentValue
                  ]?.map((item: any) => item.value);
                  return accumulator;
                },
                {}
              ),
            }
          : {
              ...panelConfiguration.preCheckedOptions,
            },
      });
    }
  }, [triggerList]);

  const handleClose = () => {
    onClose();
    setPanelConfiguration({
      ...panelConfiguration,
      preOpenPanels: {},
    });

    searchInputRef.current!.value = '';

    setActiveSlideIndex(0);
    setSenorSearchValue('');
  };

  const renderHeader = () => {
    const activeFindingPanel = activeSlideIndex === 1;

    return (
      <div className="workflow-builder-panel-header">
        <div className="workflow-builder-panel-header-icon">
          <OpusSvgIcon
            type={
              activeFindingPanel
                ? SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON
                : SVG_ICON_TYPES.SQUARE_DASHED_CIRCLE_PLUS_ICON
            }
          />
        </div>
        <h5 className="workflow-builder-panel-header-text">
          <FormattedMessage
            id={`flows.details.${
              activeFindingPanel ? 'selectFinding' : 'selectEventType'
            }`}
            defaultMessage={
              activeFindingPanel ? 'Select Finding' : 'Select event type'
            }
          />
        </h5>
      </div>
    );
  };

  const renderSearchContainer = () => {
    const activeFindingPanel = activeSlideIndex === 1;

    return (
      <div className="workflow-builder-panel-search-container">
        <TextField
          className="workflow-builder-panel-input"
          name="workflow-builder-panel-input"
          placeholder={translation(`common.search`)}
          InputProps={{
            startAdornment: (
              <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
            ),
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const inputValue = event.target.value;

            startTransition(() => {
              if (activeFindingPanel) {
                setFindingSearchValue(inputValue);
              } else {
                setSenorSearchValue(inputValue);
              }
            });
          }}
          inputRef={searchInputRef}
          value={activeFindingPanel ? findingSearchValue : sensorSearchValue}
        />
      </div>
    );
  };

  const renderFindingList = () => {
    return (
      <>
        {findingDataLoading ? (
          <div className="workflow-builder-panel-body-loading">
            <CircularProgress size={25} />
          </div>
        ) : (
          <></>
        )}

        {findingListItems?.length ? (
          <div className="workflow-builder-panel-trigger-finding-list">
            {findingListItems.map((findingListItem: IListItem) => (
              <div
                className="workflow-builder-panel-trigger-finding-list-item"
                onClick={() => {
                  onSelectFinding(findingListItem.id as string);
                }}
              >
                <div className="workflow-builder-panel-trigger-finding-list-item-checkbox">
                  <CommonCheckbox
                    checkedIcon={
                      <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_DOT_ICON} />
                    }
                    icon={<OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_ICON} />}
                    checked={
                      currentActiveFindingId === String(findingListItem.id)
                    }
                  />
                </div>
                <div className="workflow-builder-panel-trigger-finding-list-item-icon">
                  <img
                    src={findingListItem.imageMetadata?.src}
                    alt={findingListItem.imageMetadata?.alt}
                  ></img>
                </div>
                <div
                  className="workflow-builder-panel-trigger-finding-list-item-label-group"
                  title={findingListItem.label}
                >
                  <div className="workflow-builder-panel-trigger-finding-list-item-label-primary">
                    {findingListItem.label}
                  </div>
                  <div className="workflow-builder-panel-trigger-finding-list-item-label-secondary">
                    {findingListItem.subLabel}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}

        {!findingDataLoading && findingListItems?.length === 0 ? (
          <div className="workflow-builder-panel-trigger-finding-list-no-data">
            <FormattedMessage id="common.noData" defaultMessage="No Data" />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderSensorList = () => {
    return (
      <>
        {sensorTriggersLoading ? (
          <div className="workflow-builder-panel-body-loading">
            <CircularProgress size={25} />
          </div>
        ) : (
          <></>
        )}
        {sensorTriggersListItems ? (
          <OpusList
            items={sensorTriggersListItems}
            components={{
              CheckedIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.CHECKED_CHECKBOX_ICON} />
              ),
              UnCheckedIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.UNCHECKED_CHECKBOX_ICON} />
              ),
            }}
            classes={{
              root: 'workflow-builder-panel-list',
            }}
            listItemClasses={{
              root: 'workflow-builder-panel-list-item',
              counter: 'workflow-builder-panel-list-item-counter',
            }}
            preCheckedOptions={panelConfiguration.preCheckedOptions}
            preOpenPanels={panelConfiguration.preOpenPanels}
            saveCheckedValues={saveCheckedValues}
            displayChildCounter={true}
            searchKeyword={sensorSearchValue}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderBody = () => {
    const activeFindingPanel = activeSlideIndex === 1;

    return (
      <div className="workflow-builder-panel-body">
        {activeFindingPanel ? renderFindingList() : renderSensorList()}
      </div>
    );
  };

  const handleSensorClear = () => {
    setCheckedValues([]);
    setPanelConfiguration((prevPanelConfiguration) => ({
      ...prevPanelConfiguration,
      preCheckedOptions: [],
    }));
    onSave({});
    setActiveFindingId && setActiveFindingId(null);
  };

  const handleSensorSave = () => {
    const selectedOptions = Object.values(
      panelConfiguration.preCheckedOptions
    ).flat();

    if (selectedOptions.length) {
      onSave(panelConfiguration.preCheckedOptions);
      setActiveSlideIndex(1);
    }
  };

  const handleFindingSave = () => {
    setActiveFindingId &&
      currentActiveFindingId &&
      setActiveFindingId(currentActiveFindingId);
    handleClose();
  };

  const renderClearSensorButton = () => {
    if (activeSlideIndex === 0)
      return (
        <Button
          className="opus-secondary-button workflow-builder-panel-close-button"
          onClick={() => {
            handleSensorClear();
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
          <FormattedMessage
            capitalize
            id="flows.details.clearEventTypes"
            defaultMessage="Clear Event Types"
          />
        </Button>
      );

    return <></>;
  };

  const renderFooter = () => {
    const activeFindingPanel = activeSlideIndex === 1;

    return (
      <div className="workflow-builder-panel-footer">
        <div className="workflow-builder-panel-footer-start">
          {renderClearSensorButton()}
        </div>

        <div className="workflow-builder-panel-footer-end">
          <Button
            className="opus-secondary-button workflow-builder-panel-close-button"
            onClick={handleClose}
          >
            <FormattedMessage
              capitalize
              id="common.close"
              defaultMessage="Close"
            />
          </Button>
          <Button
            className="opus-primary-button workflow-builder-panel-save-button"
            onClick={() => {
              if (activeFindingPanel) {
                handleFindingSave();
              } else {
                handleSensorSave();
              }
            }}
          >
            <FormattedMessage
              capitalize
              id={`common.${activeFindingPanel ? 'save' : 'next'}`}
              defaultMessage={activeFindingPanel ? 'Save' : 'Next'}
            />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <PopoverWrapper
      showPopover={displayValue === 'block'}
      handleClose={handleClose}
      hideBackdrop
      classes="workflow-builder-panel"
    >
      <div className="workflow-builder-panel-content">
        {renderHeader()}
        <div className="workflow-builder-panel-slider">
          <Slider
            onAfterChange={(index) => {
              if (selectedTriggers?.length === 0) return;

              const selectedSensors = Object.values(
                panelConfiguration.preCheckedOptions
              ).flat();

              if (index === 1 && selectedSensors.length === 0) return;

              setActiveSlideIndex(index);
            }}
            activeIndex={activeSlideIndex}
            marks={[0, 1]}
          />
        </div>
        {renderSearchContainer()}
        {renderBody()}
        {renderFooter()}
      </div>
    </PopoverWrapper>
  );
}
